import React from "react";

const NoEvents = ({}:NoEvents) => {
    return (
        <div className="no-events">
            There are currently no upcoming events
        </div>
    );
};

interface NoEvents {

};

export default NoEvents;